import React from 'react';
import Head from "next/head";

interface Props {
    title: string;
    urlPath?: string;
    description?:  string;
}

const Meta = (props: Props) => {
    const { title, urlPath = '', description = 'Roposo Clout App' } = props;
    const url = `${process.env.NEXT_PUBLIC_ORIGIN}/${urlPath}`;

    return (
      <Head>
        <title>{title}</title>
        <meta name={'description'}
              content={description}/>
        <meta name={'og:type'}
              content={'website'}/>
        <meta name={'og:url'}
              content={url}/>
        <meta name={'og:title'}
              content={title}/>
        <meta name={'og:description'}
              content={description}/>
        <meta name={'twitter:url'}
              content={url}/>
        <meta name={'twitter:title'}
              content={title}/>
        <meta name={'twitter:description'}
              content={description}/>
      </Head>
    );
};

export default Meta;
